<template>
  <f7-card class="m-0">
    <f7-card-content>
      <f7-list
        inset
        class="PhoneAuth"
      >
        <f7-list-input
          v-if="!showCode"
          id="phoneNumber"
          outline
          required
          label="Phone number"
          floating-label
          type="tel"
          placeholder="Phone number"
          clear-button
          :value="form.phoneNumber"
          @input="form.phoneNumber = $event.target.value"
        />
        <f7-list-input
          v-if="showCode && !user"
          outline
          label="Your Name"
          floating-label
          type="text"
          placeholder="Your Name"
          clear-button
          :value="form.displayName"
          @input="form.displayName = $event.target.value"
        />
        <f7-list-input
          v-if="showCode"
          outline
          required
          label="Verification Code"
          floating-label
          type="text"
          placeholder="Verification Code"
          clear-button
          :value="form.code"
          @input="form.code = $event.target.value"
        />
      </f7-list>
      <br>
      <f7-button
        v-if="!showCode"
        fill
        @click="submitPhoneNumber()"
      >
        Sign Up
      </f7-button>

      <f7-button
        v-if="showCode"
        id="confirm-code"
        fill
        @click="submitPhoneNumberCode()"
      >
        Submit Code
      </f7-button>
    </f7-card-content>
  </f7-card>
</template>

<script>
export default {
  name: 'PhoneAuthForm',

  props: {
    user: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      showCode: false,
      form: {
        phoneNumber: null,
        displayName: null,
        code: null,
      },
    };
  },

  methods: {
    submitPhoneNumber() {
      this.$emit('onSubmitPhoneNumber', this.form);
      this.showCode = true;
    },

    submitPhoneNumberCode() {
      this.$emit('onSubmitPhoneNumberCode', this.form);
    },
  },
};
</script>

<style scoped>

</style>
