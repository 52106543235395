<template>
  <div>
    <line-chart
      :styles="chartStyles"
      :options="options"
      :chart-data="dataCollection"
    />
  </div>
</template>

<script>
import LineChart from '../js/LineChart.js';

export default {
  name: 'LineDataChart',
  components: {
    LineChart,
  },

  props: {
    history: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      dataCollection: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Month',
            },
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Value',
            },
          }],
        },
      },
    };
  },

  computed: {
    chartStyles() {
      return {
        height: '300px',
        position: 'relative',
      };
    },
  },

  mounted() {
    this.fillData();
  },

  methods: {
    fillData() {
      const labels = this.history.map(item => `${item.month}-${item.day}-${item.year}`);
      const data = this.history.map(item => parseInt(item.type));

      this.dataCollection = {
        labels,
        datasets: [
          {
            backgroundColor: '#f87979',
            data,
          },
        ],
      };
    },
  },
};
</script>

<style>
  .small {
    max-width: 600px;
    margin:  150px auto;
  }
</style>
