<template>
  <f7-page
    class="PageBgImage"
    name="quiz-page"
  >
    <f7-navbar
      title="Quiz"
      back-link="Back"
    />

    <layout>
      <f7-block-title>MENTAL HEALTH QUESTIONNAIRE</f7-block-title>

      <f7-card>
        <f7-card-content>
          Over the past two weeks, often have you been bothered by the following?
        </f7-card-content>
      </f7-card>

      <div
        v-for="(question, key) in questions"
        :key="key"
        width="100"
      >
        <f7-card>
          <f7-card-content>
            <div>{{ question.question }}</div>
            <br>

            <f7-list>
              <f7-list-item
                v-for="answer in question.answers"
                :key="answer.id"
                radio
                :title="answer.text"
                :name="`answer-${key}`"
                @change="answerQuestion(answer.id, key)"
              />
            </f7-list>
          </f7-card-content>
        </f7-card>
      </div>

      <f7-row>
        <f7-col />
        <f7-col>
          <f7-button
            fill
            round
            large
            @click="finishTest"
          >
            Finish test
          </f7-button>
        </f7-col>
        <f7-col />
      </f7-row>
    </layout>
  </f7-page>
</template>

<script>
import { mapGetters } from 'vuex';
import { QuizClass } from '../js/quiz';
import { db } from '../js/db';
import { getDateObj } from '../js/utils';
import Layout from '../components/Layout';

const TEST = 'test';

export default {
  name: 'QuizPage',

  components: {
    Layout,
  },

  data() {
    return {
      dateObj: getDateObj(),
      userId: this.$store.state.user.user.id,
      questions: [],
      test: {},
      quizClass: new QuizClass(),
    };
  },

  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
  },

  mounted() {
    this.questions = this.quizClass.getQuestions();
  },

  methods: {
    answerQuestion(answerId, key) {
      this.test[key] = answerId;
    },

    async finishTest() {
      const { date } = this.dateObj;

      const total = Object.values(this.test).reduce((a, b) => a + b, 0);
      const score = this.quizClass.scoreChart(total);
      score.test = this.test;
      score.belongsToUserId = this.user.id;
      score.time = new Date().getTime();
      score.date = date;

      if (Object.keys(this.test).length === 10) {
        await db.collection(TEST).add(score);

        this.$f7router.navigate('/');
      } else {
        this.$f7.dialog.alert('Please finish all answers before submitting the test');
      }
    },
  },
};
</script>

<style scoped></style>
