<template>
  <f7-card>
    <f7-card-content>
      <p><strong>Talk</strong></p>
      <p>National Suicide Prevention Lifeline</p>
      <f7-link
        href="tel:+18002738255"
        external
      >
        Call 1-800-273-8255
      </f7-link>
      <p><small>Available 24 hours everyday, and will connect you to a trained counselor in your area for free.</small></p>
      <hr>
      <p><strong>Text</strong></p>
      <f7-link
        href="sms://741741?&body=HOME"
        external
        style="margin-bottom: 15px"
      >
        Text HOME to 741741 (USA)
      </f7-link>
      <f7-link
        href="sms://741741?&body=HOME"
        external
      >
        Text HOME to 85258 (UK)
      </f7-link>
      <p><small>Crisis Text Line is the free, 24/7, confidential text message service for people in crisis to talk to a trained counselor in your area for free.</small></p>
    </f7-card-content>
  </f7-card>
</template>

<script>
export default {
  name: 'TalkCard',
};
</script>

<style scoped>

</style>
