<template>
  <div>
    <f7-card>
      <f7-card-header>Motivations and Goals</f7-card-header>
      <f7-card-content>
        <f7-list
          v-if="data.length"
          media-list
        >
          <f7-list-item
            v-for="(item, key) in data"
            :key="key"
            :class="`${ item.type === 'goal' ? 'b-green' : 'b-blue' }`"
            swipeout
            :title="uppercase(item.type)"
            :text="item.details"
            @swipeout:delete="removeItem(item.id)"
          >
            <f7-swipeout-actions left>
              <f7-swipeout-button
                delete
                :confirm-text="confirmText"
              >
                Delete
              </f7-swipeout-button>
            </f7-swipeout-actions>
            <f7-swipeout-actions right>
              <f7-swipeout-button
                color="green"
                @click="editItem(item.id)"
              >
                Edit
              </f7-swipeout-button>
            </f7-swipeout-actions>
          </f7-list-item>
        </f7-list>

        <div
          v-else
          class="text-gray"
        >
          <f7-progressbar
            v-if="loadsData"
            infinite
          />
          <p v-show="!loadsData">
            No Motivations and Goals
          </p>
        </div>
      </f7-card-content>
      <f7-card-footer>
        <f7-link />
        <f7-link @click="openModal">
          Add
        </f7-link>
      </f7-card-footer>
    </f7-card>

    <f7-popup
      :opened="popupOpened"
      @popup:closed="popupOpened = false"
    >
      <f7-page>
        <f7-navbar title="Motivations and Goals">
          <f7-nav-right>
            <f7-link popup-close>
              Close
            </f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-card>
          <f7-card-header>Add Motivations or Goals</f7-card-header>
          <f7-card-content>
            <f7-list>
              <f7-list-input
                label="Type"
                type="select"
                :value="form.type"
                required
                @input="form.type = $event.target.value"
              >
                <option value="motivation">
                  Motivation
                </option>
                <option value="goal">
                  Goal
                </option>
              </f7-list-input>

              <f7-list-input
                required
                label="Details"
                type="text"
                placeholder=" Motivation or Goal Details"
                :value="form.details"
                clear-button
                @input="form.details = $event.target.value"
              />
            </f7-list>
          </f7-card-content>
          <f7-card-footer>
            <f7-link />
            <f7-link @click="addEditData">
              Add
            </f7-link>
          </f7-card-footer>
        </f7-card>

        <f7-block-title>Motivation examples</f7-block-title>
        <f7-block>
          <div>What Makes You Want To Get Up In The Morning?</div>
          <div>Do you have a picture that reminds you of this, that you want to upload?</div>
        </f7-block>

        <f7-block-title>Goal examples</f7-block-title>
        <f7-block>
          <div>What Is A Goal You Have For The Next 5 Years?</div>
          <div>What Is A Goal You Have For the Next 2-3 Years?</div>
          <div>What Is A Goal You Have For the Next 1 Year?</div>
          <div>What is a Goal You Have For the Next 1-3 Months?</div>
        </f7-block>
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import { db } from '../js/db';
import { ucFirst } from '../js/utils';

const MOTIVATIONS_FRIENDS = 'motivations_friends';

export default {
  name: 'MotivationsAndGoals',

  data() {
    return {
      loadsData: true,
      confirmText: 'Are you sure you want to delete this item?',
      userId: this.$store.state.user.user.id,
      popupOpened: false,
      form: {
        id: null,
        type: '',
        details: '',
      },
      data: [],
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    uppercase(text) {
      return ucFirst(text);
    },
    getData() {
      db.collection(MOTIVATIONS_FRIENDS)
        .where('belongsToUserId', '==', this.userId)
        .onSnapshot(({ docs }) => {
          const data = [];
          docs.forEach(doc => data.push({ ...doc.data(), id: doc.id }));

          this.loadsData = false;
          this.data = data;
        });
    },

    async addEditData() {
      if (this.form.type.trim().length === 0) {
        this.$f7.dialog.alert('Type is required');
        return;
      }

      if (this.form.details.trim().length === 0) {
        this.$f7.dialog.alert('Details are required');
        return;
      }

      this.form.belongsToUserId = this.userId;

      if (this.form.id !== null) {
        await db.collection(MOTIVATIONS_FRIENDS).doc(this.form.id).set(this.form);
      } else {
        await db.collection(MOTIVATIONS_FRIENDS).add(this.form);
      }

      this.resetData();
      this.popupOpened = false;
    },

    async removeItem(itemId) {
      await db.collection(MOTIVATIONS_FRIENDS).doc(itemId).delete();
    },

    editItem(itemId) {
      this.popupOpened = true;

      const { id, type, details } = this.data.find(item => item.id === itemId);

      this.form = {
        id,
        type,
        details,
      };
    },

    openModal() {
      this.resetData();

      this.popupOpened = true;
    },

    resetData() {
      this.form = {
        id: null,
        type: '',
        details: '',
      };
    },
  },
};
</script>

<style scoped>

</style>
