<template>
  <f7-app :params="f7params">
    <!-- Your main view, should have "view-main" class -->
    <f7-view
      main
      class="safe-areas"
      url="/"
    />

    <login-modal />
  </f7-app>
</template>
<script>
import { Device } from 'framework7/framework7-lite.esm.bundle.js';
import cordovaApp from '../js/cordova-app.js';
import routes from '../js/routes.js';
import LoginModal from './LoginModal/LoginModal';

export default {
  components: {
    LoginModal,
  },
  data() {
    return {
      f7params: {
        id: 'com.masssinflux.thrive',
        name: 'Thrive',
        theme: 'auto',
        routes,
        serviceWorker: Device.cordova ? {} : {
          path: '/service-worker.js',
        },
        input: {
          scrollIntoViewOnFocus: Device.cordova && !Device.electron,
          scrollIntoViewCentered: Device.cordova && !Device.electron,
        },
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false,
        },
      },
    };
  },

  mounted() {
    this.$f7ready(f7 => {
      if (Device.cordova) {
        cordovaApp.init(f7);
      }
    });
  },
};
</script>
