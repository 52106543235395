<template>
  <f7-page
    class="PageBgImage"
    name="home"
    @page:beforeremove="onPageBeforeRemove"
  >
    <layout
      v-if="user"
      class="topBlock"
    >
      <talk :user="user" />
      <br>
      <main-menu />
      <scale />
      <quiz />
      <motivations-and-goals />
      <family-and-friends />
      <f7-block strong>
        <small><strong>Tip:</strong> Swipe left or right for more actions.</small>
      </f7-block>
    </layout>
  </f7-page>
</template>

<script>
import { mapActions } from 'vuex';
import Quiz from '../components/Quiz';
import Talk from '../components/Talk/Talk';
import Scale from '../components/Scale';
import MotivationsAndGoals from '../components/MotivationsAndGoals';
import FamilyAndFriends from '../components/FamilyAndFriends/FamilyAndFriends';
import MainMenu from '../components/MainMenu';
import Layout from '../components/Layout';

export default {
  components: {
    Layout,
    Talk,
    Quiz,
    Scale,
    MotivationsAndGoals,
    FamilyAndFriends,
    MainMenu,
  },

  data() {
    return {
      user: this.$store.state.user.user,
    };
  },

  mounted() {
    console.log(this.user);

    if (this.user === null) {
      this.$store.dispatch('ui/openLoginModal');
    }
  },

  methods: {
    ...mapActions({
      openLoginModal: 'ui/openLoginModal',
    }),

    onPageBeforeRemove() {
      if (this.popup) {
        this.popup.destroy();
      }
    },
  },
};
</script>
