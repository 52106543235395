<template>
  <f7-page
    class="PageBgImage"
    name="family-friends-details-page"
  >
    <f7-navbar
      title="Family & Friends"
      back-link="Back"
    />

    <layout>
      <div v-if="tests.length">
        <f7-card
          v-for="test in tests"
          :key="test.id"
        >
          <f7-card-header :class="[test.label.toLowerCase()]">
            {{ test.label }}
          </f7-card-header>
          <f7-card-content>
            <p class="date">
              Taken on {{ test.date }}
            </p>
            <p><strong>Self-Care Tip:</strong></p>
            <p>{{ test.familyAction }}</p>
          </f7-card-content>
        </f7-card>
      </div>
    </layout>
  </f7-page>
</template>

<script>
import Layout from '../components/Layout';
import { db } from '../js/db';
import { getDateObj } from '../js/utils';

const TEST = 'test';

export default {
  name: 'FamilyFriendsDetailsPage',

  components: {
    Layout,
  },

  data() {
    return {
      dateObj: getDateObj(),
      tests: [],
      userId: null,
    };
  },

  mounted() {
    this.userId = this.$f7route.params.userId;

    this.$f7.dialog.preloader();

    this.getTestsData();
  },

  methods: {
    async getTestsData() {
      await db.collection(TEST)
        .where('belongsToUserId', '==', this.userId)
        .orderBy('time', 'desc')
        .onSnapshot(({ docs }) => {
          const data = [];
          docs.forEach(doc => data.push({ ...doc.data(), id: doc.id }));

          if (data.length) {
            this.tests = data;
          }

          this.$f7.dialog.close();
        });
    },
  },

};
</script>
