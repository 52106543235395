import HomePage from '../pages/home.vue';
import QuizPage from '../pages/quiz-page.vue';
import QuizDetailsPage from '../pages/quiz-details-page.vue';
import ScalePage from '../pages/scale-page.vue';
import ResourcesPage from '../pages/resources-page.vue';
import AboutPage from '../pages/about-page.vue';
import FamilyFriendsDetailsPage from '../pages/family-friends-details-page.vue';

const routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/quiz-page/',
    component: QuizPage,
  },
  {
    path: '/quiz-details-page/',
    component: QuizDetailsPage,
  },
  {
    path: '/scale-page/',
    component: ScalePage,
  },
  {
    path: '/resources-page/',
    component: ResourcesPage,
  },
  {
    path: '/about-page/',
    component: AboutPage,
  },
  {
    path: '/family-friends-details-page/:userId',
    component: FamilyFriendsDetailsPage,
  },
];

export default routes;
