<template>
  <div>
    <f7-card>
      <f7-card-header>Family and Friends</f7-card-header>
      <f7-card-content>
        <div v-if="data.length">
          <f7-list media-list>
            <ul v-for="(item, key) in data">
              <family-and-friends-item
                :key="key"
                :item="item"
                @removeItem="removeItem"
                @editItem="editItem"
              />
            </ul>
          </f7-list>
        </div>
        <div
          v-else
          class="text-gray"
        >
          <f7-progressbar
            v-if="loadsData"
            infinite
          />
          <p v-show="!loadsData">
            No family or friends added
          </p>
        </div>
      </f7-card-content>
      <f7-card-footer>
        <f7-link />
        <f7-link @click="openModal">
          Add
        </f7-link>
      </f7-card-footer>
    </f7-card>

    <f7-popup
      :opened="popupOpened"
      @popup:closed="popupOpened = false"
    >
      <add-family-and-friends
        :form="form"
        :user-id="userId"
        @onSave="onSave"
      />
    </f7-popup>
  </div>
</template>

<script>
import { db } from '../../js/db';
import AddFamilyAndFriends from './AddFamilyAndFriends';
import FamilyAndFriendsItem from './FamilyAndFriendsItem';

const FAMILY_FRIENDS = 'family_friends';

export default {
  name: 'FamilyAndFriends',
  components: {
    AddFamilyAndFriends,
    FamilyAndFriendsItem,
  },

  data() {
    return {
      loadsData: true,
      userId: this.$store.state.user.user.id,
      popupOpened: false,
      data: [],
      form: {
        id: null,
      },
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      db.collection(FAMILY_FRIENDS)
        .where('belongsToUserId', '==', this.userId)
        .onSnapshot(({ docs }) => {
          const data = [];
          docs.forEach(doc => data.push({ ...doc.data(), id: doc.id }));

          if (data.length) {
            this.data = data;
          }

          this.loadsData = false;
        });
    },

    async removeItem({ id }) {
      await db.collection(FAMILY_FRIENDS).doc(id).delete();
    },

    editItem(data) {
      this.popupOpened = true;

      const { id } = this.data.find(item => item.id === data.id);

      this.form = { id };
    },

    openModal() {
      this.resetData();
      this.popupOpened = true;
    },

    onSave() {
      this.popupOpened = false;
      this.resetData();
      this.getData();
    },

    resetData() {
      this.form = {
        id: null,
      };
    },
  },
};
</script>

<style scoped>

</style>
