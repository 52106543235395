import { Line, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],

  props: {
    chartData: {
      type: Object,
      default: {},
    },
    options: {
      type: Object,
      default: {},
    },
  },

  mounted() {
    console.warn(this.chartData)
    console.warn(this.options)
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
  },
};
