<template>
  <div>
    <f7-card>
      <f7-card-header>How do you feel today?</f7-card-header>
      <f7-card-content>
        <f7-row>
          <f7-col
            v-for="id in scaleIds"
            :key="id"
          >
            <img
              :src="`../static/scale/${id}.png`"
              :class="`w-100 elevation-1 scaleButton ${data && data.type === id ? '' : 'greyscale' }`"
              alt="Thrive"
              @click="useJournal(id)"
            >
          </f7-col>
        </f7-row>

        <br>

        <f7-row v-if="history.length">
          <f7-col
            v-for="data in history"
            :key="data.id"
            class="text-center"
          >
            <f7-chip :class="['scale', `scale-${data.type}`]" />
            <div>{{ data.month }}/{{ data.day }}</div>
          </f7-col>
        </f7-row>

        <div
          v-show="showJournal"
          class="ScaleEntry"
        >
          <f7-list no-hairlines-md>
            <f7-list-input
              label="Keep a journal"
              type="textarea"
              placeholder="Add details"
              clear-button
              resizable
              :value="form.details"
              @input="form.details = $event.target.value"
            />
          </f7-list>
          <f7-block>
            <f7-button
              fill
              @click="addScale"
            >
              Add entry
            </f7-button>
          </f7-block>
        </div>
      </f7-card-content>
      <f7-card-footer>
        <f7-link />
        <f7-link href="/scale-page/">
          View History
        </f7-link>
      </f7-card-footer>
    </f7-card>
  </div>
</template>

<script>
import stripTags from 'locutus/php/strings/strip_tags';
import { db } from '../js/db';
import { getDateObj } from '../js/utils';

const SCALE = 'scale';

export default {
  name: 'Scale',
  data() {
    return {
      showJournal: false,
      dateObj: getDateObj(),
      userId: this.$store.state.user.user.id,
      scaleIds: [0, 1, 2, 3, 4],
      data: null,
      history: [],
      id: null,
      form: {
        details: '',
      },
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      const { date } = this.dateObj;

      db.collection(SCALE)
        .where('belongsToUserId', '==', this.userId)
        .orderBy('time', 'desc')
        .limit(7)
        .onSnapshot(({ docs }) => {
          let data = [];
          docs.forEach(doc => data.push({ ...doc.data(), id: doc.id }));

          data = data.map(item => {
            const [month, day, year] = item.date.split('-');
            item.month = parseInt(month);
            item.day = parseInt(day);
            item.year = parseInt(year);

            return item;
          });

          this.data = data.find(item => item.date === date) || null;
          this.history = data.reverse();
        });
    },

    useJournal(id) {
      this.id = id;
      this.showJournal = true;
    },

    async addScale() {
      const { date } = this.dateObj;

      const details = stripTags(this.form.details).replace(/\n\r?/g, '<br />');

      const body = {
        type: this.id,
        belongsToUserId: this.userId,
        time: new Date().getTime(),
        dateObj: this.dateObj,
        date,
        details,
      };

      // get data
      // const resp = await db.collection(SCALE)
      //   .where('date', '==', date)
      //   .where('belongsToUserId', '==', this.userId)
      //   .get();

      // if there is no data for today, save it
      // if (resp.docs.length === 0) {
      //   await db.collection(SCALE).add(body);
      // }

      this.showJournal = false;

      await db.collection(SCALE).add(body);
    },
  },
};
</script>

<style scoped>

</style>
