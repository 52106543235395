<template>
  <f7-popup
    :opened="googlePopup"
    :close-by-backdrop-click="false"
    :close-on-escape="false"
    :swipe-to-close="false"
  >
    <f7-page class="PageBgImage LoginModal">
      <f7-block>
        <img
          :src="`../static/logo_shadow.png`"
          class="thriveLogo"
          alt="Thrive"
        >
        <br>
        <f7-row>
          <f7-col
            width="100"
            medium="25"
          />
          <f7-col
            width="100"
            medium="50"
          >
            <f7-button
              icon-f7="logo_google"
              fill
              large
              @click="googleSignUp"
            >
              Sign Up with Google
            </f7-button>
            <!--
            <br>
            <f7-button
              icon-f7="logo_facebook"
              fill
              large
              @click="facebookSignUp"
            >
              Sign Up with Facebook
            </f7-button>
            -->
            <br>
            <f7-button
              v-show="!showPhoneSignUpForm"
              icon-f7="logo_phone"
              fill
              large
              @click="showPhoneSignUpForm = !showPhoneSignUpForm"
            >
              Sign Up with Phone
            </f7-button>
            <div id="sign-in-button" />
            <phone-auth-form
              v-show="showPhoneSignUpForm"
              :user="user"
              @onSubmitPhoneNumberCode="submitPhoneNumberCode"
              @onSubmitPhoneNumber="submitPhoneNumber"
            />
          </f7-col>
          <f7-col
            width="100"
            medium="25"
          />
        </f7-row>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import { db } from '../../js/db';
import { generate } from '../../js/uuid';
import PhoneAuthForm from './PhoneAuthForm';

const USER = 'user';

export default {
  name: 'LoginModal',

  components: {
    PhoneAuthForm,
  },

  data() {
    return {
      user: null,
      phoneNumber: null,
      displayName: null,
      showPhoneSignUpForm: false,
      recaptchaVerifier: null,
      confirmationResult: null,
    };
  },

  computed: {
    googlePopup() {
      return this.$store.state.ui.loginModal;
    },
  },

  mounted() {
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      size: 'invisible',
      callback: () => this.submitPhoneNumber,
    });
  },

  methods: {
    async submitPhoneNumber({ phoneNumber }) {
      this.phoneNumber = phoneNumber;
      const phone = `+1${phoneNumber}`;

      const resp = await db.collection(USER).where('phoneNumber', '==', phoneNumber).get();
      if (resp.docs.length) {
        this.user = resp.docs[0].data();
      }

      firebase
        .auth()
        .signInWithPhoneNumber(phone, this.recaptchaVerifier)
        .then(confirmation => (this.confirmationResult = confirmation))
        .catch(error => {
          this.showPhoneSignUpForm = false;

          this.$f7.dialog.alert('There was an error signing up with the phone number, try again lather');
          console.error(error);
        });
    },

    submitPhoneNumberCode({ code, displayName }) {
      this.displayName = displayName;
      this.confirmationResult
        .confirm(code)
        .then(async result => {
          if (!this.user) {
            await this.createUser(result);
          }

          await this.setUser();
        })
        .catch(error => (console.error(error)));
    },

    googleSignUp() {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');

      this.signUp(provider);
    },

    facebookSignUp() {
      const provider = new firebase.auth.FacebookAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');

      this.signUp(provider);
    },

    signUp(provider) {
      firebase.auth().signInWithPopup(provider).then(async result => {
        await this.createUser(result);
        await this.setUser();
      });
    },

    async createUser({ user }) {
      const body = {
        email: user.email,
        id: user.uid,
        uuid: generate(),
        phoneNumber: this.phoneNumber,
      };

      const name = user.displayName || this.displayName;
      if (name) {
        body.name = name;
      }

      await db.collection(USER).doc(user.uid).set(body);

      const resp = await db.collection(USER).doc(user.uid).get();
      this.user = resp.data();
    },

    async setUser() {
      await this.$store.dispatch('user/addUser', this.user);
      await this.$store.dispatch('ui/closeLoginModal');

      window.location.reload();
    },
  },
};
</script>

<style scoped>

</style>
