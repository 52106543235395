<template>
  <f7-card>
    <f7-card-header>Hi {{ user.name }}</f7-card-header>
    <f7-card-content class="pl-0 pr-0">
      <div class="data-table">
        <table>
          <tr>
            <td class="p-0">
              <p>Use this code to connect with your Family and Friends</p>
            </td>
            <td class="p-0">
              <f7-button
                outline
                class="clipboard"
                :data-clipboard-text="user.uuid"
                @click="copyToClipboard"
              >
                {{ user.uuid }}
              </f7-button>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="text-right">
                <small>(click to copy & share)</small>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </f7-card-content>
  </f7-card>
</template>

<script>
import Clipboard from 'clipboard';

export default {
  name: 'MenuCard',

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  methods: {
    copyToClipboard() {
      // eslint-disable-next-line no-new
      new Clipboard('.clipboard');

      if (navigator.share) {
        navigator.share({
          title: 'Thrive User ID',
          text: `${this.user.uuid}`,
          url: 'https://thrive.massinflux.com/',
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
