import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyD36FvCJH5q_oxAgWasB6Een2cKxEbmAVk',
  authDomain: 'thrive-3a41f.firebaseapp.com',
  databaseURL: 'https://thrive-3a41f.firebaseio.com',
  projectId: 'thrive-3a41f',
  storageBucket: 'thrive-3a41f.appspot.com',
  messagingSenderId: '635312733807',
  appId: '1:635312733807:web:ba69e62656ca8428ee979a',
};

// Get a Firestore instance
export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.firestore();

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore;
export { Timestamp, GeoPoint };
