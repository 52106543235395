<template>
  <f7-page
    class="PageBgImage"
    name="quiz-details-page"
  >
    <f7-navbar
      title="Mental Health Quiz"
      back-link="Back"
    />

    <layout>
      <div v-if="tests.length">
        <f7-card
          v-for="test in tests"
          :key="test.id"
        >
          <f7-card-header :class="[test.label.toLowerCase()]">
            {{ test.label }}
          </f7-card-header>
          <f7-card-content>
            <p class="date">
              Taken on {{ test.date }}
            </p>
            <p><strong>Self-Care Tip:</strong></p>
            <p>{{ test.notification }}</p>
          </f7-card-content>
        </f7-card>
      </div>

      <f7-card v-else>
        <f7-card-content class="text-gray">
          No test taken
        </f7-card-content>
        <f7-card-footer>
          <f7-link @click="$f7router.navigate('/quiz-page/')">
            Take Quiz
          </f7-link>
        </f7-card-footer>
      </f7-card>
    </layout>
  </f7-page>
</template>

<script>
import { mapGetters } from 'vuex';
import { db } from '../js/db';
import { getDateObj } from '../js/utils';
import Layout from '../components/Layout';

const TEST = 'test';

export default {
  name: 'QuizDetailsPage',

  components: {
    Layout,
  },

  data() {
    return {
      dateObj: getDateObj(),
      userId: this.$store.state.user.user.id,
      tests: [],
    };
  },

  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
  },

  mounted() {
    this.getTestsData();
  },

  methods: {
    async getTestsData() {
      await db.collection(TEST)
        .where('belongsToUserId', '==', this.userId)
        .orderBy('time', 'desc')
        .onSnapshot(({ docs }) => {
          const data = [];
          docs.forEach(doc => data.push({ ...doc.data(), id: doc.id }));

          if (data.length) {
            this.tests = data;
          }
        });
    },
  },
};
</script>

<style scoped></style>
