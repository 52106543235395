<template>
  <f7-page
    class="PageBgImage"
    name="scale"
  >
    <f7-navbar
      title="Scale History"
      back-link="Back"
    />

    <layout>
      <f7-card>
        <f7-card-content>
          <div v-if="history.length">
            <f7-row>
              <f7-col
                v-for="item in limitedHistory"
                :key="item.id"
                class="text-center"
              >
                <f7-chip :class="['scale', `scale-${item.type}`]" />
                <div>{{ item.month }}/{{ item.day }}</div>
              </f7-col>
            </f7-row>
            <br>
            <line-data-chart :history="history" />
          </div>
          <p
            v-else
            class="text-gray"
          >
            No test taken
          </p>
        </f7-card-content>
        <f7-card-footer>
          <f7-link @click="$f7router.navigate('/quiz-page/')">
            Take Quiz
          </f7-link>
        </f7-card-footer>
      </f7-card>

      <div v-if="history.length">
        <f7-card
          v-for="item in historyList"
          :key="`_${item.id}`"
        >
          <f7-card-header>
            <span v-if="item.dateObj">
              {{ item.dateObj.dateName }}
            </span>

            <span v-else>{{ item.date }}</span>
          </f7-card-header>
          <f7-card-content>
            <f7-row>
              <f7-col width="20">
                <img
                  :src="`../static/scale/${item.type}.png`"
                  :class="`w-100 elevation-1 scaleButton`"
                  alt="Thrive"
                >
              </f7-col>
              <f7-col width="80">
                <p v-html="item.details" />
              </f7-col>
            </f7-row>
          </f7-card-content>
        </f7-card>
      </div>
    </layout>
  </f7-page>
</template>

<script>
import { mapGetters } from 'vuex';
import { db } from '../js/db';
import { getDateObj } from '../js/utils';
import LineDataChart from '../components/LineDataChart';
import Layout from '../components/Layout';

const SCALE = 'scale';

export default {
  name: 'ScalePage',
  components: {
    LineDataChart,
    Layout,
  },

  data() {
    return {
      dateObj: getDateObj(),
      userId: this.$store.state.user.user.id,
      data: [],
      history: [],
      limitedHistory: [],
    };
  },

  computed: {
    ...mapGetters({
      user: 'user/user',
    }),

    historyList() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.history.reverse();
    },
  },

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      const { date } = this.dateObj;

      db.collection(SCALE)
        .where('belongsToUserId', '==', this.userId)
        .orderBy('time', 'desc')
        .limit(100)
        .onSnapshot(({ docs }) => {
          let data = [];
          docs.forEach(doc => data.push({ ...doc.data(), id: doc.id }));

          data = data.map(item => {
            const [month, day, year] = item.date.split('-');
            item.month = parseInt(month);
            item.day = parseInt(day);
            item.year = parseInt(year);

            return item;
          });

          this.data = data.find(item => item.date === date) || null;
          this.history = data.reverse();
          this.limitedHistory = this.history.slice(0, 7);
        });
    },
  },
};
</script>

<style scoped></style>
