<template>
  <div>
    <f7-row>
      <f7-col
        width="100"
        medium="25"
        large="33"
      />
      <f7-col
        width="100"
        medium="50"
        large="33"
      >
        <slot />
      </f7-col>
      <f7-col
        width="100"
        medium="25"
        large="33"
      />
    </f7-row>
    <br>
    <br>
    <br>
  </div>
</template>

<script>
export default {
  name: 'Layout',
};
</script>
