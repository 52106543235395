<template>
  <f7-card>
    <f7-card-header>Mental Health Quiz</f7-card-header>
    <f7-card-content>
      <div v-if="test">
        <p class="date">
          Taken on {{ test.date }}
        </p>
        <p><strong>Self-Care Tip:</strong></p>
        <p>{{ test.notification }}</p>
      </div>
      <div
        v-else
        class="text-gray"
      >
        <f7-progressbar
          v-if="loadsData"
          infinite
        />
        <p v-show="!loadsData">
          No test taken today
        </p>
      </div>
    </f7-card-content>
    <f7-card-footer>
      <f7-link href="/quiz-page/">
        Take Quiz
      </f7-link>
      <f7-link href="/quiz-details-page/">
        View Details
      </f7-link>
    </f7-card-footer>
  </f7-card>
</template>

<script>
import { db } from '../js/db';
import { getDateObj } from '../js/utils';

const TEST = 'test';

export default {
  name: 'Quiz',

  data() {
    return {
      loadsData: true,
      dateObj: getDateObj(),
      userId: this.$store.state.user.user.id,
      test: null,
    };
  },

  mounted() {
    this.getTestsData();
  },

  methods: {
    async getTestsData() {
      await db.collection(TEST)
        .where('belongsToUserId', '==', this.userId)
        .orderBy('time', 'desc')
        .limit(1)
        .onSnapshot(({ docs }) => {
          const data = [];
          docs.forEach(doc => data.push({ ...doc.data(), id: doc.id }));

          if (data.length) {
            this.test = data[0];
          }

          this.loadsData = false;
        });
    },
  },
};
</script>

<style scoped>

</style>
