<template>
  <f7-page
    class="PageBgImage"
    name="about-page"
  >
    <f7-navbar
      title="About Thrive"
      back-link="Back"
    />

    <layout>
      <f7-card>
        <f7-card-header>Thrive is a toolkit of mental health resources you can access from your phone</f7-card-header>
        <f7-card-content>
          <p>Thrive allows individuals to take control of their mental health with features including recording mental health status and tracking changes in mood, setting goals you can reflect back on when you are feeling down, sharing your information with your support network including family and friends to reduce the awkwardness of getting help and support. Thrive is also developing a growing list of resources to help you connect and get more help online and in your community for mental health.</p>
        </f7-card-content>
      </f7-card>
    </layout>
  </f7-page>
</template>

<script>
import Layout from '../components/Layout';

export default {
  name: 'AboutPage',

  components: {
    Layout,
  },
};
</script>
