/**
 * @returns {{date: string, dateName: string, month: number, year: number, monthName: string, day: number}}
 */
export function getDateObj() {
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  const dateObj = new Date();
  const month = dateObj.getUTCMonth() + 1;
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();
  const monthName = monthNames[dateObj.getMonth()];

  const seconds = dateObj.getSeconds();
  const minutes = dateObj.getMinutes();
  const hour = dateObj.getHours();

  const date = `${month}-${day}-${year}`;
  const dateName = `${monthName} ${day}, ${year}`;

  return {
    month,
    day,
    year,
    date,
    monthName,
    dateName,
    hour,
    minutes,
    seconds,
  };
}

export function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
