<template>
  <f7-page
    class="PageBgImage"
    name="resources-page"
  >
    <f7-navbar
      title="Resources"
      back-link="Back"
    />

    <layout>
      <f7-card>
        <f7-card-header>Resources</f7-card-header>
        <f7-card-content>
          <f7-list media-list>
            <f7-list-item
              title="Suicide prevention lifeline"
              subtitle="1-800-273-8255 (TALK) TTY: 1-800-799-4889"
              text="Free and confidential support for people in distress, 24/7."
            />
            <f7-list-item
              title="National Helpline"
              subtitle="1-800-662-HELP (4357) TTY: 1-800-487-4889"
              text="Treatment referral and information, 24/7."
            />
            <f7-list-item
              title="Disaster Distress Helpline"
              subtitle="Helpline 1-800-985-5990 TTY: 1-800-846-8517"
              text="Immediate crisis counseling related to disasters, 24/7."
            />
          </f7-list>
        </f7-card-content>
      </f7-card>
    </layout>
  </f7-page>
</template>

<script>
import { mapGetters } from 'vuex';
import Layout from '../components/Layout';

export default {
  name: 'ResourcesPage',

  components: {
    Layout,
  },

  data() {
    return {
      userId: this.$store.state.user.user.id,
    };
  },

  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
  },

  mounted() {

  },

  methods: {

  },
};
</script>
