<template>
  <f7-page>
    <f7-navbar title="Family and Friends">
      <f7-nav-right>
        <f7-link popup-close>
          Close
        </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-card>
      <f7-card-header>Add Family or Friend</f7-card-header>
      <f7-card-content>
        <f7-list>
          <f7-list-input
            outline
            floating-label
            type="text"
            label="Enter code to connect Family or Friend"
            :value="code"
            @input="code = $event.target.value"
          />
        </f7-list>
      </f7-card-content>
      <f7-card-footer>
        <f7-link />
        <f7-link @click="connectCode">
          Connect Family/Friend
        </f7-link>
      </f7-card-footer>
    </f7-card>

    <f7-block-title>How notifications work?</f7-block-title>
    <f7-block>
      <p>Enter the Family & Friends code to connect.</p>
      <p>Family & Friends will receive your Test scores.</p>
    </f7-block>
  </f7-page>
</template>

<script>
import { db } from '../../js/db';

const FAMILY_FRIENDS = 'family_friends';
const USER = 'user';

export default {
  name: 'AddFamilyAndFriends',

  props: {
    form: {
      type: Object,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      code: '',
    };
  },

  methods: {
    async connectCode() {
      if (this.code.trim().length) {
        const code = this.code.trim();

        const resp = await db.collection(USER).where('uuid', '==', code).get();
        if (resp.docs.length) {
          const { id, name } = resp.docs[0].data();

          this.$f7.dialog.confirm(`Connect user ${name}?`, () => this.connectUser(id));
        } else {
          this.$f7.dialog.alert('This code is invalid');
        }
      } else {
        this.$f7.dialog.alert('Code is required');
      }
    },

    async connectUser(connectUserId) {
      this.form.belongsToUserId = this.userId;
      this.form.connectUserId = connectUserId;

      if (this.form.id !== null) {
        await db.collection(FAMILY_FRIENDS).doc(this.form.id).update(this.form);
      } else {
        await db.collection(FAMILY_FRIENDS).add(this.form);
      }

      this.$emit('onSave', this.form);
    },
  },
};
</script>

<style scoped>

</style>
