<template>
  <f7-list-item
    swipeout
    :title="name"
    :after="date"
    :subtitle="`Tests: ${tests.length}`"
    :text="`${connectedUser ? 'Connected' : 'Not Connected' }`"
    @swipeout:delete="removeItem(item.id)"
  >
    <f7-icon
      slot="media"
      :color="`${connectedUser ? 'green' : '' }`"
      :f7="`${connectedUser ? 'checkmark_alt_circle' : 'minus_circle' }`"
    />
    <f7-swipeout-actions left>
      <f7-swipeout-button
        delete
        :confirm-text="confirmText"
      >
        Delete
      </f7-swipeout-button>
    </f7-swipeout-actions>
    <f7-swipeout-actions right>
      <f7-swipeout-button
        color="blue"
        @click="viewDetails"
      >
        Details
      </f7-swipeout-button>
      <f7-swipeout-button
        color="green"
        @click="editItem(item.id)"
      >
        Edit
      </f7-swipeout-button>
    </f7-swipeout-actions>
  </f7-list-item>
</template>

<script>
import { db } from '../../js/db';

const TEST = 'test';
const USER = 'user';

export default {
  name: 'FamilyAndFriendsItem',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      confirmText: 'Are you sure you want to delete this item?',
      tests: [],
      connectedUser: null,
    };
  },

  computed: {
    name() {
      return this.connectedUser ? this.connectedUser.name : '';
    },
    date() {
      return this.tests.length ? this.tests[0].date : '';
    },
  },

  async mounted() {
    await this.getConnectionFamilyAndFriends();

    if (this.connectedUser) {
      this.getTestsData();
    }
  },

  methods: {
    async getConnectionFamilyAndFriends() {
      const { connectUserId } = this.item;

      const resp = await db.collection(USER)
        .doc(connectUserId)
        .get();

      this.connectedUser = resp.exists ? resp.data() : null;
    },

    getTestsData() {
      const { connectUserId } = this.item;

      db.collection(TEST)
        .where('belongsToUserId', '==', connectUserId)
        .orderBy('time', 'desc')
        .onSnapshot(({ docs }) => {
          const data = [];
          docs.forEach(doc => data.push({ ...doc.data(), id: doc.id }));

          if (data.length) {
            this.tests = data;
          }

          this.$f7.dialog.close();
        });
    },

    viewDetails() {
      if (!this.tests) {
        this.$f7.dialog.alert('No tests found for this user');
      } else {
        const { connectUserId } = this.item;

        this.$f7router.navigate(`/family-friends-details-page/${connectUserId}/`);
      }
    },

    removeItem(id) {
      this.$emit('removeItem', { id });
    },

    editItem(id) {
      this.$emit('editItem', { id });
    },
  },
};
</script>
