/**
 * @returns {{loginModal: boolean}}
 */
const state = () => ({
  loginModal: false,
});

const getters = {
  loginModal: (state) => {
    return state.loginModal;
  },
};

const actions = {
  openLoginModal({ commit }) {
    commit('setLoginModal', true);
  },
  closeLoginModal({ commit }) {
    commit('setLoginModal', false);
  }
};

const mutations = {
  setLoginModal (state, data) {
    state.loginModal = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
