import Vue from 'vue';
import Vuex from 'vuex';
import ui from './modules/ui';
import user from './modules/user';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ui,
    user,
  },
  plugins: [new VuexPersistence().plugin]
});
