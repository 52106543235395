export class QuizClass {
  constructor() {
    this.questions = this.getQuestions();
  }

  getQuestions() {
    const basicAnswer = [{ id: 0, text: 'Not at all' }, { id: 1, text: 'Several days' }, { id: 2, text: 'More than half the days' }, { id: 3, text: 'Nearly every day' }];
    const secondAnswer = [{ id: 0, text: 'Not difficult at all' }, { id: 1, text: 'Somewhat difficult' }, { id: 2, text: 'Very difficult' }, { id: 3, text: 'Extremely difficult' }];

    return [
      {
        question: 'Little interest or pleasure in doing things',
        answers: basicAnswer,
      },
      {
        question: 'Feeling down, depressed, or hopeless',
        answers: basicAnswer,
      },
      {
        question: 'Trouble falling or staying asleep, or sleeping too much',
        answers: basicAnswer,
      },
      {
        question: 'Feeling tired or having little energy',
        answers: basicAnswer,
      },
      {
        question: 'Poor appetite or overeating',
        answers: basicAnswer,
      },
      {
        question: 'Feeling bad about yourself or that you are a failure or have let yourself or your family down',
        answers: basicAnswer,
      },
      {
        question: 'Trouble concentrating on things, such as reading the newspaper or watching television',
        answers: basicAnswer,
      },
      {
        question: 'Moving or speaking so slowly that other people could have noticed. Or the opposite being so fidgety or restless that you have been moving around a lot more than usual',
        answers: basicAnswer,
      },
      {
        question: 'Thoughts that you would be better off dead, or of hurting yourself',
        answers: basicAnswer,
      },
      {
        question: 'If any of these have been a problem for you, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?',
        answers: secondAnswer,
      },
    ];
  }

  scoreChart(total) {
    const data = [
      {
        'key': 0,
        'label': 'Minimal',
        'treatment': 'None',
        'notification': 'None',
        'class': 'tag-green',
        'familyAction': 'Send them a text or call them to say “Hi” and that you’re thinking about them.',
        'values': [0, 1, 2, 3, 4],
      },
      {
        'key': 1,
        'label': 'Mild',
        'treatment': 'Watchful waiting; repeat PHQ-9 at follow-up',
        'notification': 'Watchful waiting; repeat Questionnaire at follow-up',
        'familyAction': 'Send them a text or call them to say “Hi” and that you’re thinking about them, or surprise them with something. Some ideas: tell them a joke, cook their favorite food for dinner, or go for a walk or hike together.',
        'class': 'tag-blue',
        'values': [5, 6, 7, 8, 9],
      },
      {
        'key': 2,
        'label': 'Moderate',
        'treatment': 'Treatment plan, considering counseling, follow-up and/or pharmacotherapy',
        'notification': 'Treatment plan, considering counseling, follow-up and/or pharmacotherapy',
        'familyAction': 'Give them a call or plan some time this week when you could spend time with them and talk about what’s bothering them. You could talk on the phone, or go for a walk, or do an activity together such as cooking, where you can do something and talk at the same time to make it feel less awkward.',
        'class': 'tag-yellow',
        'values': [10, 11, 12, 13, 14],
      },
      {
        'key': 3,
        'label': 'Moderate',
        'treatment': 'Active treatment with pharmacotherapy and/or psychotherapy',
        'notification': 'Active treatment with pharmacotherapy and/or psychotherapy',
        'familyAction': 'Give them a call or plan some time this week when you could spend time with them and talk about what’s bothering them. You could talk on the phone, or go for a walk, or do an activity together such as cooking, where you can do something and talk at the same time to make it feel less awkward.',
        'class': 'tag-orange',
        'values': [15, 16, 17, 18, 19],
      },
      {
        'key': 4,
        'label': 'Severe',
        'treatment': 'Immediate initiation of pharmacotherapy and, if severe impairment or poor response to therapy, expedited referral to a mental health specialist for psychotherapy and/or collaborative management',
        'notification': 'Immediate initiation of pharmacotherapy and, if severe impairment or poor response to therapy, expedited referral to a mental health specialist for psychotherapy and/or collaborative management',
        'familyAction': 'Go to their house or see if someone else is available who can be with them in person, today, and talk to them about going to the doctor or getting help immediately.',
        'class': 'tag-red',
        'values': [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      },
    ];

    let value = {
      label: 'none',
      treatment: 'none',
      notification: 'none',
      class: 'tag-red',
    };

    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].values.length; j++) {
        if (data[i].values[j] === total) {
          value = data[i];
        }
      }
    }

    return value;
  }
}
