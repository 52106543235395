<template>
  <div class="MainMenu">
    <f7-row class="MainMenu-row">
      <f7-col>
        <f7-button
          small
          fill
          color="blue"
          href="/resources-page/"
        >
          Resources
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          small
          fill
          color="blue"
          href="/about-page/"
        >
          About
        </f7-button>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
export default {
  name: 'MainMenu',
};
</script>
