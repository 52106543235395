<template>
  <div class="MainMenu">
    <f7-row>
      <f7-col width="25">
        <f7-button
          small
          color="black"
          class="menu"
          icon-f7="menu"
          @click="menu"
        />
      </f7-col>
      <f7-col width="75">
        <f7-button
          fill
          small
          color="red"
          icon-size="18"
          icon-f7="phone_fill"
          class="talkWithSomeone f-r"
          @click="share"
        >
          TALK WITH SOMEONE
        </f7-button>
      </f7-col>
    </f7-row>

    <div v-if="showMenu">
      <menu-card :user="user" />
    </div>

    <div v-if="showTalkCard">
      <talk-card />
    </div>
  </div>
</template>

<script>
import TalkCard from './TalkCard';
import MenuCard from './MenuCard';

export default {
  name: 'Talk',

  components: {
    MenuCard,
    TalkCard,
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showTalkCard: false,
      showMenu: false,
    };
  },

  methods: {
    menu() {
      this.showMenu = !this.showMenu;
      this.showTalkCard = false;
    },
    share() {
      this.showTalkCard = !this.showTalkCard;
      this.showMenu = false;
    },
  },
};
</script>

<style scoped>

</style>
