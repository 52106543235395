/**
 * @returns {{user: null}}
 */
const state = () => ({
  user: null,
});

/**
 * @type {{user: (function(*): *)}}
 */
const getters = {
  user: state => {
    return state.user;
  },
};

/**
 * @type {{addUser({state: *, commit: *}, *=): void}}
 */
const actions = {
  addUser({ state, commit }, user) {
    commit('setUser', user);
  },
};

/**
 * @type {{setUser(*, *): void}}
 */
const mutations = {
  setUser(state, user) {
    state.user = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
